<template>
  <div>
    <div>
      <!-- <img
        style="
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          display: block;
          width: 100%;
          z-index: -1;
        "
        src="../assets/image/homepage.jpg"
      /> -->
      <headPage />
      <!-- 自动灌溉 -->
      <div style="position: relative; height: 638px; overflow: hidden">
        <img
          style="display: block; width: 100%"
          src="../assets/image/otherScenarios/image1.png"
        />
        <div class="info" data-aos="fade-right">
          <img class="img1" src="../assets/icon/image14.png" />
          <div class="title1">自动灌溉</div>
          <div class="title1-1">
            土壤湿度监测，远程自动控制灌溉，进行精准化灌溉，节水节能，智慧灌溉解决方案，支持定制。
          </div>
        </div>
      </div>
      <!-- 虚拟高尔夫 -->
      <div class="box2">
        <img
          style="display: block; width: 100%; height: 730px"
          src="../assets/image/otherScenarios/image2.png"
        />
        <div class="info" data-aos="fade-left">
          <img class="img2" src="../assets/icon/image15.png" />
          <div class="title2">虚拟高尔夫</div>
          <div class="title1-2">
            模拟高尔夫是仿真室外高尔夫而做的一项室内运动。</br>
            任由外面寒风呼啸，在温暖的室内，纵情挥杆，自在畅享。
          </div>
        </div>
      </div>
      <!-- 出入识别 -->
      <div class="box3">
        <img
          style="display: block; width: 100%; transform: translate(37px, -29px)"
          src="../assets/image/otherScenarios/image3.png"
        />
        <div class="info" data-aos="fade-right">
          <img class="img3" src="../assets/icon/image16.png" />
          <div class="title3">车辆出入识别</div>
          <div class="title1-3">
            车辆识别系统， 通过识别车牌联动家里的大门或卷帘门自动开启
            ，同时联动相应场景功能。
          </div>
        </div>
      </div>
      <!-- 智能镜 -->
      <div class="box4" style="position: relative">
        <img
          style="display: block; width: 100%; transform: translate(-1px, -3px)"
          src="../assets/image/otherScenarios/image4.png"
        />
        <div class="info" data-aos="fade-left">
          <img class="img4" src="../assets/icon/image17.png" />
          <div class="title4">智能魔镜</div>
          <div class="title1-4">
            魔镜内置了AI智能语音助手，从选播音乐、看视频、读新闻到控制家电设备都可实现全语音控制，同时还能联动家里的智能家居实现场景化控制。
          </div>
        </div>
      </div>
    </div>
    <footPage />
  </div>
</template>
<script>
import headPage from "@/components/head.vue";
import footPage from "@/components/foot.vue";
export default {
  methods: {
    jump(url) {
      this.$router.push(url);
    },
  },
  components: {
    headPage,
    footPage,
  },
};
</script>
<style lang="scss">
.title1-4 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  position: absolute;
  width: 517px;
  top: 505px;
  right: 293px;
  text-align: right;
  font-size: 16px;
}
.title1-3 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  position: absolute;
  top: 463px;
  left: 264px;
  width: 477px;
  justify-content: space-between;
  text-align: justify;
  font-size: 16px;
}
.title1-2 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  position: absolute;
  width: 424px;
  top: 458px;
  right: 259px;
  text-align: right;
  font-size: 16px;
}
.title1-1 {
  font-family: "SourceHanSansCN-Light";
  color: #b0acad;
  position: absolute;
  top: 386px;
  left: 323px;
  width: 461px;
  justify-content: space-between;
  text-align: justify;
  font-size: 16px;
}
.box4 {
  height: 718px;
}
.box3 {
  position: relative;
  height: 708px;
  overflow: hidden;
  background-color: black;
  margin-top: -6px;
}
.box2 {
  position: relative;
  height: 730px;
  overflow: hidden;
  transform: translate(0px, -5px);
}
.info {
  width: 100%;
  height: 638px;
  position: absolute;
  top: 0;
}
.img1 {
  display: block;
  width: 7%;
  position: absolute;
  left: 390px;
  top: 204px;
}
.title1 {
  position: absolute;
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  color: #d0ac32;
  left: 323px;
  top: 286px;
}
.img2 {
  display: block;
  width: 7%;
  position: absolute;
  right: 355px;
  top: 243px;
  transform: translate(-3px, -1px);
}
.img3 {
  display: block;
  width: 14%;
  position: absolute;
  left: 293px;
  top: 320px;
  transform: translate(54px, -40px);
}
.title2 {
  position: absolute;
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  color: #d0ac32;
  right: 276px;
  top: 352px;
}
.title3 {
  position: absolute;
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  color: #d0ac32;
  left: 259px;
  top: 356px;
}
.title4 {
  position: absolute;
  font-family: "SourceHanSansCN-Medium";
  font-size: 62px;
  color: #d0ac32;
  right: 293px;
  top: 399px;
}
.img4 {
  display: block;
  width: 7%;
  position: absolute;
  right: 362px;
  top: 301px;
  transform: translate(-1px, -3px);
}
</style>